import { CurveHeader } from './../Components/CurveHeader';
import UpeuLogo from '../Assets/upeuLogo.png';
import Woman from '../Assets/woman.svg';
import { ResultsBox } from './../Containers/ResultsBox';

export function Results() {
    return (
        <>
            <div className="view__background" />
            <CurveHeader />
            <div className="results__logo__contain">
                <img src={UpeuLogo} alt="" className="results__logo" />
            </div>
            <div className="svg__container results__woman">
                <img src={Woman} alt="woman" />
            </div>
            <ResultsBox />
        </>
    )
}