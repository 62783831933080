import Boy from '../Assets/boy.svg';
import Girl from '../Assets/girl.svg';

export const StartContainer = () => {
    return (
        <>
            <div className="start__background" />
            <div className="svg__container boy__container">
                <img src={Boy} alt="React Logo" />
            </div>
            <div className="svg__container girl__container">
                <img src={Girl} alt="React Logo" />
            </div>
            <div className="text__container text__test">
                <p>Test</p>
            </div>
            <div className="text__container text__upeu">
                <p>UPeU</p>
            </div>
        </>
    )
}