const url = "https://apitestvocacional.upeu.edu.pe/api/gps_person";

export function sendAlumno(alumno: any) {
    console.log("--------------------------------------")
    console.log("Alumno enviado")
    console.table(alumno)
    console.log("--------------------------------------")
    return fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(alumno),
    });
}

export const shareResult = (data:any, id:any) => {
    console.log("--------------------------------------")
    console.log("Guardando foto en backend")
    console.log("--------------------------------------")
    return fetch(`${url}/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  };