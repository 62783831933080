import { useHistory } from "react-router-dom";
import { useDispatch, useStore } from "../../Store/StoreProvider";
import { types } from "../../Store/StoreReducer";

export const ResultsBox = () => {

    let history = useHistory();

    const { user, results } = useStore();

    const dispatch = useDispatch();

    function handleLogout() {
        dispatch({
            type: types.authLogout
        })
        history.push("/")
    }

    function handleCareer(val: number) {
        history.push(`/result/${val}`)
    }

    return (
        <div className="results__box">
            <div className="div__point" />
            <p className="results__title">{!results.hasOwnProperty("message") && "Felicitaciones"}</p>
            <p className="results__name">{user.PER_NAME}</p>
            <p className="results__text">{!results.hasOwnProperty("message") ? ("Estos son tus resultados") : ("No hemos definido tu perfil 🙁, te sugerimos un test personal")}</p>
            <hr className="results__hr" />
            <p className="results__visualize">{!results.hasOwnProperty("message") && "Visualiza aquí"}</p>
            <div className="results__buttons">
                {!results.hasOwnProperty("message") ?
                    (
                        results.map((val: any, index: any) =>
                            <button key={index} className="results__button my__button primary"
                                onClick={() => handleCareer(index)}>{val.PERFIL}</button>
                        )
                    ) :
                    (<button className="results__button my__button primary" onClick={handleLogout}>Finalizar</button>)
                }
            </div>
        </div>
    )
}