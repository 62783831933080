import { motion } from 'framer-motion';
export const ChatItem = ({ text, type, gender }: any) => {
    return (
        <motion.div initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, transition: { duration: 0.15 } }}
            transition={{ ease: "easeOut", duration: 0.5 }} className="chat__item__contain">
            {type && <img src={gender} alt="user" className="chat__icon" />}
            <div className={`chat__item ${type ? "me" : "other"}`}>{text}</div>
        </motion.div>
    )
}