export interface Person {
    PER_ID?: string,
    PER_COUNT: string,
    PER_NAME: string,
    PER_F_LAST_NAME: string,
    PER_M_LAST_NAME: string,
    PER_TYPE_DOCUMENT: string,
    PER_N_DOCUMENT: string,
    PER_GRADE: string,
    PER_PHONE: string,
    PER_EMAIL: string,
    PER_SEX: string,
}

export const voidUser = {
    PER_COUNT: "",
    PER_NAME: "",
    PER_F_LAST_NAME: "",
    PER_M_LAST_NAME: "",
    PER_TYPE_DOCUMENT: "",
    PER_N_DOCUMENT: "",
    PER_GRADE: "",
    PER_PHONE: "",
    PER_EMAIL: "",
    PER_SEX: ""
}