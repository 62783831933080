import { useHistory, useParams } from "react-router-dom";
import { CurveHeader } from './../Components/CurveHeader';
import UpeuLogo from '../Assets/upeuLogo.png';
import Check from '../Assets/check.svg'
import { CarrerResult } from './../Components/CareerResult';
import Modal from './../Components/Modal';
import arte from '../Assets/profiles/ARTÍSTICA.png'
import asis from '../Assets/profiles/ASISTENCIAL.png'
import calc from '../Assets/profiles/CÁLCULO.png'
import comu from '../Assets/profiles/COMUNICACIONAL.png'
import econ from '../Assets/profiles/ECONÓMICA.png'
import huma from '../Assets/profiles/HUMANÍSTICA.png'
import ling from '../Assets/profiles/LINGÜÍSTICA.png'
import musi from '../Assets/profiles/MUSICAL.png'
import sani from '../Assets/profiles/SANITARIA.png'
import tecn from '../Assets/profiles/TECNOLÓGICA.png'
import natu from '../Assets/profiles/NATURALISTA.jpg'
import domtoimage from 'dom-to-image';
import { useRef } from "react";
import { useDispatch, useStore } from "../../Store/StoreProvider";
import { types } from "../../Store/StoreReducer";
import { shareResult } from "../../Services/AlumnoService";

export function Result() {

    const careers: any = {
        "ARTÍSTICA": arte,
        "ASISTENCIAL": asis,
        "CÁLCULO": calc,
        "COMUNICACIONAL": comu,
        "ECONÓMICA": econ,
        "HUMANÍSTICA": huma,
        "LINGÜÍSTICA": ling,
        "MUSICAL": musi,
        "SANITARIA": sani,
        "TECNOLÓGICA": tecn,
        "NATURALISTA": natu
    }

    let { id }: any = useParams();

    const { user, results, modal } = useStore();

    const all = useRef(null);

    let history = useHistory();

    const dispatch = useDispatch()

    function handleClick() {
        dispatch({ type: types.authLogout })
        history.push('/');
    }

    function handleBack() {
        history.goBack()
    }

    const share = (url: any) => {
        var t = "Mis resultados";
        window.open(
            "http://www.facebook.com/sharer.php?u=" +
            encodeURIComponent(url) +
            "&p=" +
            encodeURIComponent(t),
            "sharer",
            "toolbar=0,status=0,width=626,height=436"
        );
        return false;
    };

    function convertirPDF(val: any) {
        dispatch({ type: types.giftChange, payload: true })
        document.body.classList.toggle("no_responsive");
        document.getElementById("mi__logo")!.classList.toggle("results__logoo");
        domtoimage.toPng(all.current!, { quality: 0.95 })
            .then(function (dataUrl) {
                if (val) {
                    var link = document.createElement('a');
                    link.download = 'Mis resultados.jpeg';
                    link.href = dataUrl;
                    link.click();
                } else {
                    var sharing: any
                    var linkfinalBase64 = dataUrl.split("data:image/png;base64,", 2);
                    if (results[id] === 0) {
                        sharing = {
                            img_One: `https://apitestvocacional.upeu.edu.pe/storage/images/imageupeu1-${user.PER_ID}.jpg`,
                            img_Two: "",
                            img_base64: linkfinalBase64[1],
                        };
                    } else {
                        sharing = {
                            img_One: "",
                            img_Two: `https://apitestvocacional.upeu.edu.pe/storage/images/imageupeu2-${user.PER_ID}.jpg`,
                            img_base64: linkfinalBase64[1],
                        };
                    }
                    shareResult(sharing, user.PER_ID).then(() => {
                        if (results[id] === 0) {
                            share(sharing.img_One);
                        } else {
                            share(sharing.img_Two);
                        }
                    });
                }
                document.body.classList.toggle("no_responsive");
                document.getElementById("mi__logo")!.classList.toggle("results__logoo");
                dispatch({ type: types.giftChange, payload: false });
            });
    }

    return (
        <>

            <div className="foto" ref={all} >
                <CurveHeader />
                <div id="mi__logo" className="results__logo__contain results__logoo">
                    <img src={UpeuLogo} alt="" className="results__logo" />
                </div>
                <i className="fas fa-power-off result__icon" onClick={handleClick}></i>
                <i className="fas fa-arrow-alt-circle-left back__icon " onClick={handleBack}></i>
                <div className="result__container">
                    <div className="result__profile">
                        <div className="result__title">Felicitaciones, {user.PER_NAME}</div>
                        <div className="result__subtitle">Tu perfil vocacional es:</div>
                        <div className="result__detail">
                            <div className="profile__image__contain">
                                <div className="profile__image__box">
                                    <img src={careers[results[id].PERFIL]} alt="" className="profile__image" />
                                    <img src={Check} alt="" className="profile__check" />
                                </div>
                            </div>
                            <div className="profile__description">
                                <div className="profile__title">{results[id].PERFIL}</div>
                                <div className="profile__text">
                                    {results[id].DESCRIPTION}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="profile__image__contain__min">
                            <div className="profile__image__box">
                                <img src={careers[results[id].PERFIL]} alt="" className="profile__image" />
                                <img src={Check} alt="" className="profile__check" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="result__careers">
                    <button className="results__button my__button primary"
                        onClick={() => convertirPDF(true)}>Descargar&nbsp;<i className="fas fa-download"></i></button>
                    <button className="results__button my__button primary"
                        onClick={() => convertirPDF(false)}>Compartir&nbsp;<i className="fas fa-share"></i></button>
                    <br />
                    <br />
                    <div className="careers__title">Carreras que puedes estudiar</div>
                    <div className="careers__list">
                        {results[id].CAREERS[0].R_UPEU.map((val: any, index: any) => <CarrerResult key={index} name={val.CARRERA} link={val.LINK} type={true} id={val.ID} />)}
                        <br />
                        {results[id].CAREERS[0].R_OTHERS.map((val: any, index: any) => <CarrerResult key={index} name={val.CARRERA} link={val.LINK} />)}
                    </div>
                    <div className="result__link">
                        <a className="my__link" target="_blank" rel="noopener noreferrer" href="https://www.upeu.edu.pe/">www.upeu.edu.pe</a>
                    </div>
                </div>
                {modal && <Modal />}
                <div className="view__background" />
            </div>
        </>
    )
}