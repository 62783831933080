const url = "https://apitestvocacional.upeu.edu.pe/api/gps_result";
const crm = "https://apitestvocacional.upeu.edu.pe/api/gps_profile";

export function sendAnswers(list: any) {
    console.log("--------------------------------------")
    console.log("Respuestas enviadas")
    console.log(JSON.stringify(list))
    console.log("--------------------------------------")
    return fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(list),
    });
}

export function destroyCRM(data: any) {
    console.log("Disparando al CRM")
    console.log(JSON.stringify(data));
    console.log("__________________________________")
    return fetch(crm, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
}