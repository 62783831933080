import { CurveHeader } from './../Components/CurveHeader';
import UpeuLogo from '../Assets/upeuLogo.png';
import { ChooseContent } from './../Containers/ChooseContent';
import { ChooseNext } from './../Containers/ChooseNext';

export function Choose() {

    return (
        <>
            <CurveHeader />
            <div className="view__background" />
            <div className="choose__logo">
                <img src={UpeuLogo} alt="" className="upeu__logo__choose" />
            </div>
            <ChooseContent />
            <ChooseNext />
        </>
    )
}