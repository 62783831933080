import { StartButton } from '../Containers/StartButton';
import { StartContainer } from '../Containers/StartContainer';

export function Start() {
    return (
        <>
            <StartContainer />
            <StartButton />
        </>
    )
}