import { ChatItem } from './../Components/ChatItem';
import Boy from '../Assets/boyIcon.png';
import Girl from '../Assets/girlIcon.png';
import Face from '../Assets/face.svg';
import Img from '../Assets/img.svg';
import Happy from '../Assets/happy.svg';
import Sad from '../Assets/sad.svg';
import { useState } from 'react';
import { useDispatch, useStore } from '../../Store/StoreProvider';
import { types } from '../../Store/StoreReducer';
import { useHistory } from 'react-router-dom';
import { sendAnswers } from '../../Services/ProfilesService';
export const ChatBox = () => {

    const [bottom, setBottom] = useState(0);
    const [count, setCount] = useState(0);
    const [items, setItems] = useState<any[]>([]);
    const [myValues, setmyValues] = useState<any>([]);
    const [myText, setmyText] = useState<string[]>(["Empezar!!!", "Enviar"]);

    const { user, questions, values } = useStore();

    const dispatch = useDispatch()

    let history = useHistory();

    function handleClick(val: number, text: string) {
        if (count === questions.length - 1) {
            setItems([...items, {
                QTN_NAME: text,
                QTN_TYPE: true
            }]);
            setmyValues([...myValues, {
                QTN_ID: questions[count].QTN_ID.toString(),
                RST_ANSWER: val.toString(),
                UL: "1"
            }])
            console.log(values)
            setmyText(["Ver mis resultados", "Aceptar"])
            setBottom(0);
        } else {
            setItems([...items, {
                QTN_NAME: text,
                QTN_TYPE: true
            }, questions[count + 1]])
            setmyValues([...myValues, {
                QTN_ID: questions[count].QTN_ID.toString(),
                RST_ANSWER: val.toString(),
            }])
        }
        setCount(count + 1);
    }

    return (
        <div className="text__chat">

            <div className="header__chat">
                <div className="chat__input">
                    <input type="text" className="chat__look" />
                    <i className="fas fa-search search__icon"></i>
                </div>
                <div className="icon__container">
                    <i className="fas fa-microphone my__icon"></i>
                </div>
                <div className="icon__container">
                    <i className="fas fa-bell my__icon"></i>
                </div>
            </div>
            <div className="body__chat">
                <div className="body__header">
                    <div>
                        <p className="chat__user">{user.PER_NAME}&nbsp;</p>
                    </div>
                    <div className="chat__online">
                        <p className="text__online">online&nbsp;</p>
                    </div>
                    <p className="online__point">•</p>
                </div>
                <div className="chat__body">
                    <div>
                        {items.map((val: any, index) => <ChatItem
                            key={index}
                            text={val.QTN_NAME}
                            type={val.QTN_TYPE}
                            gender={user.PER_SEX === "1" ? Boy : Girl}
                        />)}
                    </div>
                </div>
            </div>
            <div className="text__contain">
                <div className="bottom__chat">
                    {bottom === 0
                        ? <div className="classic__bottom">
                            <div className="pictures__contain bottom__contain">
                                <img src={Face} alt="" />
                                <img src={Img} alt="" />
                            </div>
                            <div className="input__contain bottom__contain">
                                <p className="bottom__input" >{myText[0]}</p>
                            </div>
                            <div className="button__cotain bottom__contain">
                                <button className="my__button primary test__button" onClick={() => {
                                    if (count > questions.length - 1) {
                                        user.PER_COUNT++;
                                        dispatch({ type: types.userChange, payload: user })
                                        dispatch({ type: types.giftChange, payload: true })
                                        sendAnswers([{
                                            PER_ID: values[0].PER_ID.toString(),
                                            PER_COUNT: (parseInt(user.PER_COUNT)).toString(),
                                            QUESTION: myValues,
                                        }])
                                            .then((res) => res.json())
                                            .then((val) => {
                                                console.log("--------------------------------------")
                                                console.log("Respuesta de backend")
                                                console.log(val)
                                                console.log("--------------------------------------")
                                                dispatch({
                                                    type: types.resultsChange,
                                                    payload: val
                                                })
                                                history.push("/results");
                                                dispatch({ type: types.giftChange, payload: false })
                                            });
                                    } else {
                                        setBottom(1);
                                        setItems([...items, questions[count]]);
                                    }
                                }}>
                                    {myText[1]}&nbsp;&nbsp;
                                    <i className="fas fa-paper-plane test__icon"></i>&nbsp;
                                </button>
                            </div>
                        </div>
                        : <div className="answer__contain bottom__contain">
                            <div className="answer__bottom button__negative" onClick={() => handleClick(0, "Nunca")}>
                                Nunca
                                <img className="answer__emoji" src={Sad} alt="happy" />
                            </div>
                            <div className="answer__bottom  button__negative" onClick={() => handleClick(1, "A veces")}>
                                A veces <h2>🤔</h2>
                            </div>
                            <div className="answer__bottom  button__negative" onClick={() => handleClick(2, "Siempre")}>
                                Siempre
                                <img className="answer__emoji" src={Happy} alt="happy" />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}