import React, { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface IProtectedRouteProps extends RouteProps { 
    guard?: boolean
 }

const ProtectedRoute: FC<IProtectedRouteProps> = ({ guard = true, ...rest }) => {
    if (guard) return <Route {...rest} />
    return <Redirect to='/' />;
}
export default ProtectedRoute;