import { useHistory } from "react-router";
import { useDispatch, useStore } from "../../Store/StoreProvider";
import { types } from "../../Store/StoreReducer";
import { MyButton } from "../Components/MyButton"
import { motion } from "framer-motion"
import { sendList } from './../../Services/QuestionsService';
import arte from '../Assets/profiles/ARTÍSTICA.png'
import asis from '../Assets/profiles/ASISTENCIAL.png'
import calc from '../Assets/profiles/CÁLCULO.png'
import comu from '../Assets/profiles/COMUNICACIONAL.png'
import econ from '../Assets/profiles/ECONÓMICA.png'
import huma from '../Assets/profiles/HUMANÍSTICA.png'
import ling from '../Assets/profiles/LINGÜÍSTICA.png'
import musi from '../Assets/profiles/MUSICAL.png'
import sani from '../Assets/profiles/SANITARIA.png'
import tecn from '../Assets/profiles/TECNOLÓGICA.png'
import natu from '../Assets/profiles/NATURALISTA.jpg'

export const ChooseNext = () => {

    const careers: any = {
        "ARTÍSTICA": arte,
        "ASISTENCIAL": asis,
        "CÁLCULO": calc,
        "COMUNICACIONAL": comu,
        "ECONÓMICA": econ,
        "HUMANÍSTICA": huma,
        "LINGÜÍSTICA": ling,
        "MUSICAL": musi,
        "SANITARIA": sani,
        "TECNOLÓGICA": tecn,
        "NATURALISTA": natu
    }

    let history = useHistory();

    const { profiles, choosed, checked, voids } = useStore();

    const dispatch = useDispatch()

    function handleClick() {
        dispatch({ type: types.giftChange, payload: true })
        sendList([{
            CHOOSED: checked.slice(0, 3)
        }]).then((res) => res.json())
            .then(async (val) => {
                console.log("--------------------------------------")
                console.log("Respuesta de backend")
                console.log(val)
                console.log("--------------------------------------")
                let data = Object.keys(val)
                let myQuestions: any = []
                data.forEach((myVal: any, index) => myQuestions = [...myQuestions, ...val[data[index]]])
                myQuestions.map((myVal: any) => {
                    myVal.QTN_TYPE = false;
                    return myVal;
                })
                let newVal = myQuestions.sort((a: any, b: any) => {
                    return a.QTN_ID - b.QTN_ID;
                });
                console.log("--------------------------------------")
                console.log("Preguntas ordenadas por ID")
                console.log(newVal)
                console.log("--------------------------------------")
                dispatch({
                    type: types.questionsChange,
                    payload: newVal
                })
                dispatch({ type: types.giftChange, payload: false })
            })
        history.push("/test");
    }

    const variants = {
        visible: { rotate: [0, 10, 0, -10, 0, 10, 0, -10, 0] },
        hidden: {},
    }

    return (
        <div className="next__box">
            <div className="choosed__images">

                {[...profiles].sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0)).map((val: any, index: any) => {
                    return (
                        <motion.img
                            animate={val.choosed ? "visible" : "hidden"}
                            transition={{ ease: "linear", duration: 1 }}
                            variants={variants}
                            className="choosed__image"
                            key={index}
                            style={{ display: !val.choosed ? "none" : "inline-block" }}
                            src={careers[val.NAME]} alt=""
                        />
                    )
                })}
                {
                    voids.map((val: any, index: any) => <motion.div key={index} className="choosed__image" animate={{ rotate: [0, 10, 0, -10, 0, 10, 0, -10, 0] }}
                        transition={{ ease: "linear", duration: 1 }} />)
                }
            </div>
            <div className="choose__buttom">
                <MyButton title="Pasar al siguiente" onClick={() => { if (choosed > 2) handleClick() }} />
            </div>
        </div>
    )
}