import Logo from '../Assets/logo.svg'
import { RegisteredContainer } from './../Containers/RegisteredContainer';
export function Registered() {

    return (
        <>
            <div className="view__background" />
            <div className="form__container">

                <RegisteredContainer />
            </div>

            <div className="form__logo__contain">
                <img src={Logo} className="upeu__logo" alt="mira" />
            </div>
        </>
    )
}