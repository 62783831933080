import { motion } from "framer-motion"
import { useStore } from "../../Store/StoreProvider"
import Logo from '../Assets/logo.svg'

const Gift = () => {

    const { gift } = useStore()

    return (
        <>
            {gift && <div>
                <div className="gift__background" />
                <div className="gift" />
                <div className="load__container">
                    <motion.div animate={{ scale: [1, 1.2, 1.5, 1.7, 2, 1.7, 1.5, 1.2, 1] }}
                        transition={{
                            repeat: Infinity,
                            duration: 1,
                        }} className="load l1" />
                    <motion.div animate={{ scale: [1.2, 1.5, 1.7, 2, 1.7, 1.5, 1.2, 1, 1.2] }}
                        transition={{
                            repeat: Infinity,
                            duration: 1,
                        }} className="load l2" />
                    <motion.div animate={{ scale: [1.5, 1.7, 2, 1.7, 1.5, 1.2, 1, 1.2, 1.5] }}
                        transition={{
                            repeat: Infinity,
                            duration: 1,
                        }} className="load l3" />
                    <motion.div animate={{ scale: [1.7, 2, 1.7, 1.5, 1.2, 1, 1.2, 1.5, 1.7] }}
                        transition={{
                            repeat: Infinity,
                            duration: 1,
                        }} className="load l4" />
                </div>
                <div className="form__logo__contain">
                    <img src={Logo} className="upeu__logo" alt="mira" />
                </div>
            </div>}
        </>
    )
}

export default Gift