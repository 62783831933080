import { CurveHeader } from "../Components/CurveHeader";
import UpeuLogo from '../Assets/upeuLogo.png';
import { ChatBox } from './../Containers/ChatBox';
import Boy from '../Assets/boy.svg';
import Girl from '../Assets/girl.svg';
import { useStore } from "../../Store/StoreProvider";

export function Test() {

    const { user } = useStore();

    return (
        <>
            <div className="view__background" />
            <CurveHeader />
            <div className="test__logo">
                <img src={UpeuLogo} alt="" className="logo__test" />
            </div>
            <div className="test__text">
                <strong>¿Estás listo</strong> para realizar <strong>el Test?</strong>
            </div>
            
            <div className="svg__container person__chat">
                <img src={user.PER_SEX === "1" ? Boy : Girl} alt="React Logo" />
            </div>
            <ChatBox/>
        </>
    )
}