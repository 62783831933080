import { useHistory } from "react-router";
import { MyBox } from '../Components/MyBox';
import { useDispatch } from "../../Store/StoreProvider";
import { useState } from "react";
import { Person, voidUser } from "../../Model/AlumnoModel";
import { sendAlumno } from "../../Services/AlumnoService";
import { types } from "../../Store/StoreReducer";

export const FormContainer = () => {

    const [styles, setStyles] = useState({
        name: "black",
        FName: "black",
        MName: "black",
        sex: "black",
        grade: "black",
        IDType: "black",
        IDNumber: "black",
        phone: "black",
        email: "black",
    })

    const [user, setUser] = useState<Person>(voidUser);

    const dispatch = useDispatch()

    let history = useHistory();

    function validateEmail(email: string) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function validateNumber(input: string) {
        const re = /^[0-9]+$/;
        return re.test(input);
    }

    function validation(val: number) {

        let v1 = document.querySelector('input[name="name"]') as HTMLInputElement;
        let v2 = document.querySelector('input[name="lastName"]') as HTMLInputElement;;
        let v3 = document.querySelector('input[name="mothers last name"]') as HTMLInputElement;
        let v4 = document.querySelector('select[name="sex"]') as HTMLSelectElement;
        let v5 = document.querySelector('select[name="grade"]') as HTMLSelectElement;
        let v6 = document.querySelector('select[name="document"]') as HTMLSelectElement;
        let v7 = document.querySelector('input[name="dni"]') as HTMLInputElement;
        let v8 = document.querySelector('input[name="phone"]') as HTMLInputElement;
        let v9 = document.querySelector('input[name="email"]') as HTMLInputElement;

        var flag1 = true;
        var flag2 = true;
        var flag3 = true;
        var flag4 = true;
        var flag5 = true;
        var flag6 = true;
        var flag7 = true;
        var flag8 = true;
        var flag9 = true;

        var final = { ...styles }

        if (val >= 1 || val === 0) {
            if (v1.value === "") {
                final = { ...final, name: "red" }
                flag1 = false;
            } else {
                final = { ...final, name: "LawnGreen" }
                flag1 = true;

            }
        }
        if (val >= 2 || val === 0) {
            if (v2.value === "") {
                final = { ...final, FName: "red" }
                flag2 = false;
            } else {
                final = { ...final, FName: "LawnGreen" }
                flag2 = true;
            }
        }
        if (val >= 3 || val === 0) {
            if (v3.value === "") {
                final = { ...final, MName: "red" }
                flag3 = false;
            } else {
                final = { ...final, MName: "LawnGreen" }
                flag3 = true;
            }
        }
        if (val >= 4 || val === 0) {
            if (v4.value === "-" || user.PER_SEX === "-") {
                final = { ...final, sex: "red" }
                flag4 = false;
            } else {
                final = { ...final, sex: "LawnGreen" }
                flag4 = true;
            }
        }
        if (val >= 5 || val === 0) {
            if (v5.value === "-" || user.PER_GRADE === "-") {
                final = { ...final, grade: "red" }
                flag5 = false;
            } else {
                final = { ...final, grade: "LawnGreen" }
                flag5 = true;
            }
        }
        if (val >= 6 || val === 0) {
            if (v6.value === "-" || user.PER_TYPE_DOCUMENT === "-") {
                final = { ...final, IDType: "red" }
                flag6 = false;
            } else {
                final = { ...final, IDType: "LawnGreen" }
                flag6 = true;
            }
        }
        if (val >= 7 || val === 0) {
            if (v7.value === "") {
                final = { ...final, IDNumber: "red" }
                flag7 = false;
            } else if (user.PER_N_DOCUMENT.length < 8) {
                final = { ...final, IDNumber: "orange" }
                flag7 = false;
            } else {
                final = { ...final, IDNumber: "LawnGreen" }
                flag7 = true;
            }
        }
        if (val >= 8 || val === 0) {
            if (v8.value === "") {
                final = { ...final, phone: "red" }
                flag8 = false;
            } else {
                final = { ...final, phone: "LawnGreen" }
                flag8 = true;
            }
        }
        if (val >= 9 || val === 0) {
            if (v9.value === "" || !validateEmail(user.PER_EMAIL)) {
                final = { ...final, email: "red" }
                flag9 = false;
            } else {
                final = { ...final, email: "LawnGreen" }
                flag9 = true;
            }
        }

        setStyles(final);

        return flag1 && flag2 && flag3 && flag4 && flag5 && flag6 && flag7 && flag8 && flag9;
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        if (validation(0)) {
            dispatch({ type: types.giftChange, payload: true });
            sendAlumno(user)
                .then((res) => res.json())
                .then(async (val) => {
                    console.log("--------------------------------------")
                    console.log("Respuesta de backend")
                    console.log(val)
                    console.log("--------------------------------------")
                    user.PER_ID = val.alumn[0][0].ID
                    user.PER_COUNT = val.alumn[0][0].COUNT
                    dispatch({
                        type: types.authLogin,
                        payload: user
                    });
                    dispatch({
                        type: types.valuesChange,
                        payload: [{
                            PER_ID: user.PER_ID,
                            PER_COUNT: val.alumn[0][0].COUNT,
                            QUESTION: [],
                        }]
                    })
                    let profiles = val.profiles[0]
                    profiles.forEach((val: any, index: any) => profiles[index] = {
                        ...profiles[index], choosed: false, position: 0
                    })
                    dispatch({
                        type: types.profilesAdd,
                        payload: profiles
                    })
                    dispatch({ type: types.giftChange, payload: false })
                    history.push("/registered");
                    setTimeout(function () {
                        history.push('/choose')
                    }, 1000);
                })
        }
    }

    return (
        <MyBox styles="mycol-4">
            <div className="div__point" />
            <p className="form__title">Regístrate</p>
            <div className="container">
                <form onSubmit={handleSubmit}>
                    <input className="form__input" name="name" type="text" placeholder="Nombre (s)"
                        style={{ borderColor: styles.name }}
                        onBlur={() => validation(1)}
                        onChange={e => {
                            user.PER_NAME = e.target.value;
                            setUser(user);
                            validation(1);
                        }} />
                    <input className="form__input" name="lastName" type="text" placeholder="Apellido Paterno"
                        style={{ borderColor: styles.FName }}
                        onBlur={() => {
                            validation(2)
                        }}
                        onChange={e => {
                            user.PER_F_LAST_NAME = e.target.value;
                            setUser(user);
                            validation(2);
                        }} />
                    <input className="form__input" name="mothers last name" type="text" placeholder="Apellido Materno"
                        style={{ borderColor: styles.MName }}
                        onBlur={() => validation(3)}
                        onChange={e => {
                            user.PER_M_LAST_NAME = e.target.value;
                            setUser(user);
                            validation(3);
                        }} />
                    <select className="form__select" name="sex"
                        style={{ borderColor: styles.sex }}
                        onBlur={() => validation(4)}
                        onChange={e => {
                            user.PER_SEX = e.target.value;
                            setUser(user);
                            validation(4);
                        }}>
                        <option value="-">Sexo</option>
                        <option value="1">Masculino</option>
                        <option value="0">Femenino</option>
                    </select>
                    <select className="form__select" name="grade"
                        style={{ borderColor: styles.grade }}
                        onBlur={() => validation(5)}
                        onChange={e => {
                            user.PER_GRADE = e.target.value;
                            setUser(user);
                            validation(5);
                        }}>
                        <option value="-">Grado</option>
                        <option value="Terminé el colegio">Terminé el colegio</option>
                        <option value="Cuarto grado">Estoy en 4to°</option>
                        <option value="Quinto grado">Estoy en 5to°</option>
                    </select>
                    <select className="form__select" name="document"
                        style={{ borderColor: styles.IDType }}
                        onBlur={() => validation(6)}
                        onChange={e => {
                            user.PER_TYPE_DOCUMENT = e.target.value;
                            setUser(user);
                            validation(6);
                        }}>
                        <option value="-">Tipo de documento</option>
                        <option value="1" >DNI</option>
                        <option value="0">Carné de extranjería</option>
                    </select>
                    <input className="form__input" name="dni" type="text" placeholder="Número de identificación"
                        maxLength={8}
                        style={{ borderColor: styles.IDNumber }}
                        onBlur={() => validation(7)}
                        onChange={e => {
                            if (!validateNumber(e.target.value)) e.target.value = e.target.value.slice(0, -1);
                            user.PER_N_DOCUMENT = e.target.value
                            setUser(user)
                            validation(7);
                        }} />
                    <input className="form__input" name="phone" type="text" placeholder="Teléfono"
                        style={{ borderColor: styles.phone }}
                        onBlur={() => validation(8)}
                        onChange={e => {
                            if (!validateNumber(e.target.value)) e.target.value = e.target.value.slice(0, -1);
                            user.PER_PHONE = e.target.value;
                            setUser(user);
                            validation(8);
                        }} />
                    <input className="form__input" name="email" type="text" placeholder="Correo"
                        style={{ borderColor: styles.email }}
                        onBlur={() => validation(9)}
                        onChange={e => {
                            user.PER_EMAIL = e.target.value;
                            setUser(user);
                            validation(9);
                        }} />
                    <input type="submit" className="my__button primary" value="Enviar datos" />
                </form>
            </div>
        </MyBox>
    )
}