import { FormContainer } from './../Containers/FormContainer';
import Logo from '../Assets/logo.svg'

export function Form() {
    return (
        <>
            <div className="view__background" />

            <div className="form__container">
                <FormContainer />
            </div>
            <div className="form__logo__contain">
                <img src={Logo} className="upeu__logo" alt="mira" />
            </div>
        </>
    )
}