import { useHistory } from "react-router-dom";
import { useDispatch, useStore } from "../../Store/StoreProvider";
import { types } from "../../Store/StoreReducer";
import { MyButton } from '../Components/MyButton';

export function StartButton() {

    let history = useHistory();

    const dispatch = useDispatch();

    const { user } = useStore();

    function handleClick() {
        dispatch({
            type: types.authLogout
        })
        console.log(user)
        history.push("/form");
    }

    return (
        <div className="btn_container">
            <div className="btn__row">
                <MyButton onClick={handleClick} title={"INICIAR AQUÍ"} />
            </div>
        </div>
    )
}