import { motion } from "framer-motion"

export const ChooseBox = ({ title, text, choosed, onClick, image, index }: any) => {


    return (
        <div className="choose__box">
            <div className={`box__notAbsolute`} onClick={onClick}>
                <div className="box__title">
                    {title}
                </div>
                <div className="box__text">
                    {text}
                </div>
                <img className="box__image" src={image} alt="" />
                {choosed && <motion.div animate={{ scale: [1, 1.5, 1] }}
                    transition={{ duration: 0.5 }} className="box__checked"><p>{index}</p></motion.div>}
            </div>
        </div>
    )
}