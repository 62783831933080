import { useDispatch } from "../../Store/StoreProvider"
import { types } from "../../Store/StoreReducer"

const Modal = ({ children }: any) => {

    const dispatch = useDispatch()

    return (
        <>
            <div className="modal__background" >
                <div className="my__modal">
                    <div className="modal__contain">
                        <div className="div__point point__1" />
                        <div className="modal__close" onClick={() => {
                            dispatch({ type: types.modalChange, payload: false })
                            document.body.style.overflow = 'unset';
                        }}>X</div>
                        <p className="modal__gracias">Gracias</p>
                        <hr className="modal__hr" />
                        <p className="modal__text">Un asesor UPeU</p>
                        <p className="modal__text">se comunicará contigo</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal
