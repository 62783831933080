import { MouseEventHandler } from 'react';

interface IMyButtonProps {
    onClick: MouseEventHandler,
    title: string,
    className?: string,
    children?: any
}

export const MyButton = ({ className = "primary", title, onClick, children }: IMyButtonProps) => {
    return (
        <button className={`my__button ${className}`} onClick={onClick}>{title}{children}</button>
    )
}