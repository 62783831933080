import { destroyCRM } from '../../Services/ProfilesService'
import { useDispatch, useStore } from '../../Store/StoreProvider'
import { types } from '../../Store/StoreReducer'
import Check from '../Assets/check.svg'

export const CarrerResult = ({ id = 0, name, link, type = false }: any) => {

    const { user } = useStore();

    const dispatch = useDispatch()

    return (
        <div className="career__result">
            <div className="career__title">
                <img src={Check} alt="" className="career_check" />
                <div className={`career__name ${type ? 'another' : ''}`} >{name}</div>
            </div>
            {type && <a href={link} target="_blank" rel="noopener noreferrer" onClick={() => {
                destroyCRM({ "ID_PERSON": `${user.PER_ID}`, "ID_CAREER": id })
                dispatch({ type: types.modalChange, payload: true })
                document.body.style.overflow = 'hidden';
            }} className="career__button my__button primary mas__informacion">Más información</a>}
        </div>
    )
}
