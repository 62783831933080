import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useStore } from "../Store/StoreProvider";
import { Choose } from "../UI/Views/Choose";
import { Form } from "../UI/Views/Form";
import { Registered } from "../UI/Views/Registered";
import { Result } from "../UI/Views/Result";
import { Results } from "../UI/Views/Results";
import { Start } from "../UI/Views/Start";
import { Test } from "../UI/Views/Test";
import ProtectedRoute from "./ProtectedRoute";

export default function Routes() {

    const { auth } = useStore();

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Start} />
                <Route path="/form" component={Form} />
                <ProtectedRoute path="/registered" component={Registered} guard={auth} />
                <ProtectedRoute path="/choose" component={Choose} guard={auth} />
                <ProtectedRoute path="/test" component={Test} guard={auth} />
                <ProtectedRoute path="/results" component={Results} guard={auth} />
                <ProtectedRoute path="/result/:id" component={Result} guard={auth} />
                <ProtectedRoute path="/result" component={Start} guard={auth} />
            </Switch>
        </BrowserRouter>
    )
}