import { useDispatch, useStore } from "../../Store/StoreProvider";
import { types } from "../../Store/StoreReducer";
import { ChooseBox } from "../Components/ChooseBox"
import arte from '../Assets/profiles/ARTÍSTICA.png'
import asis from '../Assets/profiles/ASISTENCIAL.png'
import calc from '../Assets/profiles/CÁLCULO.png'
import comu from '../Assets/profiles/COMUNICACIONAL.png'
import econ from '../Assets/profiles/ECONÓMICA.png'
import huma from '../Assets/profiles/HUMANÍSTICA.png'
import ling from '../Assets/profiles/LINGÜÍSTICA.png'
import musi from '../Assets/profiles/MUSICAL.png'
import sani from '../Assets/profiles/SANITARIA.png'
import tecn from '../Assets/profiles/TECNOLÓGICA.png'
import natu from '../Assets/profiles/NATURALISTA.jpg'

export const ChooseContent = () => {

    const careers: any = {
        "ARTÍSTICA": arte,
        "ASISTENCIAL": asis,
        "CÁLCULO": calc,
        "COMUNICACIONAL": comu,
        "ECONÓMICA": econ,
        "HUMANÍSTICA": huma,
        "LINGÜÍSTICA": ling,
        "MUSICAL": musi,
        "SANITARIA": sani,
        "TECNOLÓGICA": tecn,
        "NATURALISTA": natu,
    }

    const { profiles, choosed, checked } = useStore();

    const dispatch = useDispatch()

    return (
        <div className="choose__content">
            <p className="choose__subtitle">Selecciona de acuerdo a <strong>tu interés</strong> </p>
            <br />
            <div className="choose__careers">
                {profiles.map((val: any) => {
                    return (
                        <ChooseBox key={val.ID} title={val.NAME} image={careers[val.NAME]}
                            text={val.PRESENTATION} choosed={val.choosed} index={val.position}
                            onClick={() => {
                                let newCount = 0
                                if (val.choosed) {
                                    if (choosed <= 3) dispatch({
                                        type: types.voidsChange,
                                        payload: choosed === 3 ? [1] :
                                            choosed === 2 ? [1, 1] : [1, 1, 1]
                                    })
                                    newCount = choosed === 1 ? newCount : choosed - 1;
                                    dispatch({
                                        type: types.profilesUpdate,
                                        payload: profiles.map((myVal: any) => {
                                            if (myVal.position > 1 && myVal.position > val.position) myVal.position--;
                                            return myVal;
                                        })
                                    })
                                    dispatch({
                                        type: types.checkedChange,
                                        payload: checked.filter((myVal: any) => myVal !== val.ID)
                                    })
                                    dispatch({ type: types.choosedChange, payload: newCount })
                                    dispatch({
                                        type: types.profilesChange,
                                        payload: { ...val, choosed: !val.choosed }
                                    })
                                } else {
                                    if (choosed < 3) {
                                        dispatch({
                                            type: types.voidsChange,
                                            payload: choosed === 0 ? [1, 1] :
                                                choosed === 1 ? [1] : []
                                        })
                                        newCount = choosed + 1
                                        val.position = newCount;
                                        dispatch({
                                            type: types.checkedChange,
                                            payload: [...checked, val.ID]
                                        })
                                        dispatch({ type: types.choosedChange, payload: newCount })
                                        dispatch({
                                            type: types.profilesChange,
                                            payload: { ...val, choosed: !val.choosed }
                                        })
                                    }
                                }
                            }}
                        />
                    )
                })}
            </div>
        </div>
    )
}