import Boy from '../Assets/boyIcon.png';
import Girl from '../Assets/girlIcon.png';
import Check from '../Assets/check.svg'
import { MyBox } from '../Components/MyBox';
import { useStore } from '../../Store/StoreProvider';
import { motion } from "framer-motion"

export const RegisteredContainer = () => {

    const { user } = useStore();

    return (
        <MyBox styles="mycol-3">
            <div className="div__point point__1" />
            <div className="r__icon__container">
                <div className="icon__row">
                    <img className="registered__icon" src={user.PER_SEX === "1" ? Boy : Girl} alt={user.PER_SEX === "1" ? "Boy Icon" : "Girl Icon"} />
                    <motion.img animate={{ scale: [1, 1.5, 1, 1.5, 1] }} className="check__icon" src={Check} alt="Check Icon" />
                </div>
                <p className="registered__title">Registrado</p>
                <p className="registered__text">Hola {user.PER_NAME}, te has registrado correctamente</p>
            </div>
        </MyBox>
    )
}