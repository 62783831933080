const url = "https://apitestvocacional.upeu.edu.pe/api/gps_question";

export function sendList(list: any) {
    console.log("--------------------------------------")
    console.log("Perfiles elegidos")
    console.log(JSON.stringify(list))
    console.log("--------------------------------------")
    return fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(list),
    });
}