const types = {
    authLogin: 'auth - login',
    authLogout: 'auth - logout',
    userChange: 'user - change',
    valuesChange: 'vales - change',
    questionsChange: 'questions - change',
    profilesAdd: 'profiles - add',
    profilesChange: 'profiles - change',
    profilesUpdate: 'profiles - update',
    choosedChange: 'choosed - change',
    resultsChange: 'results - change',
    checkedChange: 'checked - change',
    voidsChange: 'voids - change',
    modalChange: 'modal - change',
    giftChange: 'gift - change'
}

const initialStore = {
    user: {
        PER_TYPE_DOCUMENT: "",
        PER_GRADE: ""
    },
    values: {},
    questions: [],
    profiles: [],
    choosed: 0.0,
    checked: [],
    results: [],
    voids: [1, 1, 1],
    auth: false,
    modal: false,
    gift: false
}

const storeReducer = (state: any, action: any) => {
    switch (action.type) {
        case types.authLogin:
            return {
                ...state,
                user: action.payload,
                auth: true
            }
        case types.authLogout:
            return {
                ...state,
                user: {
                    PER_TYPE_DOCUMENT: "",
                    PER_GRADE: ""
                },
                values: {},
                questions: [],
                profiles: [],
                choosed: 0.0,
                checked: [],
                results: [],
                voids: [1, 1, 1],
                auth: false,
                modal:false,
                gift: false
            }
        case types.valuesChange:
            return {
                ...state,
                values: action.payload
            }
        case types.userChange:
            return {
                ...state,
                user: action.payload
            }
        case types.questionsChange:
            return {
                ...state,
                questions: action.payload
            }
        case types.profilesAdd:
            return {
                ...state,
                profiles: action.payload
            }
        case types.profilesChange:
            const profileEdit = action.payload
            return {
                ...state,
                profiles: state.profiles.map((val: any) => val.ID === profileEdit.ID ? profileEdit : val)
            }
        case types.profilesUpdate:
            return {
                ...state,
                profiles: action.payload
            }
        case types.choosedChange:
            return {
                ...state,
                choosed: action.payload
            }
        case types.resultsChange:
            return {
                ...state,
                results: action.payload
            }
        case types.checkedChange:
            return {
                ...state,
                checked: action.payload
            }
        case types.voidsChange:
            return {
                ...state,
                voids: action.payload
            }
        case types.modalChange:
            return {
                ...state,
                modal: action.payload
            }
        case types.giftChange:
            return {
                ...state,
                gift: action.payload
            }
        default:
            return state;
    }
}

export { initialStore, types };
export default storeReducer;