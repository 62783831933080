import Index from './Routes/Routes';
import './UI/Styles/Globals.css'
import './UI/Styles/ViewBackground.css'
import './UI/Styles/UpeuLogo.css'
import './UI/Styles/MyBox.css'
import './UI/Styles/MyButton.css';
import './UI/Styles/FormRegister.css'
import './UI/Styles/FormInput.css'
import './UI/Styles/FormSelect.css'
import './UI/Styles/RegisteredBox.css'
import './UI/Styles/StartButton.css';
import './UI/Styles/StartSVG.css';
import './UI/Styles/StartBackground.css';
import './UI/Styles/StartText.css';
import './UI/Styles/CurveHeader.css';
import './UI/Styles/UpeuTitle.css';
import './UI/Styles/ChooseView.css';
import './UI/Styles/ChooseBox.css';
import './UI/Styles/TestView.css';
import './UI/Styles/ChatBox.css';
import './UI/Styles/BodyHeader.css';
import './UI/Styles/ResultsBox.css';
import './UI/Styles/ResultView.css';
import './UI/Styles/Grids.css';
import './UI/Styles/Gift.css';
import './UI/Styles/NoResponsive.css';

import StoreProvider from './Store/StoreProvider';
import Gift from './UI/Components/Gift';

function App() {
  return (
    <StoreProvider>
      < Index />
      <Gift />
    </StoreProvider>
  );
}

export default App;